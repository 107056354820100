import React, { useRef } from "react";
import Button from "@mui/material/Button";
import { Box } from "@mui/material";
import { CameraEnhance } from "@mui/icons-material";

export const ImageUploadButton = ({ variant = "contained", color = "primary", size = "medium", handleFileChange, buttonText }) => {
  const fileInputRef = useRef(null);

  const handleFileUpload = () => {
    fileInputRef.current.click();
  };

  const handleFileSelected = (event) => {
    const file = event.target.files[0];

    handleFileChange(file);
  };

  return (
    <Box>
      <input type="file" accept="image/*" capture="camera" ref={fileInputRef} style={{ display: "none" }} onChange={handleFileSelected} />
      <Button size={size} color={color} startIcon={<CameraEnhance />} variant={variant} onClick={handleFileUpload}>
        {buttonText}
      </Button>
    </Box>
  );
};
