import api from "../utils/axios-client";

/**
 * Gets all auto complete converters
 */
const getAutoCompleteConverters = async () => api.get("/v1/catalytic-converters/simple/auto-complete");

/**
 * Gets all mapped converters for a vin
 * @param {string} vin - vin number getting details for.
 */
const getDetailsFromVin = async (vin) =>
  api.get(`/v1/catalytic-converters/detailed/as-user`, {
    params: {
      vin: vin,
    },
  });

const CatalyticConverterService = {
  getAutoCompleteConverters,
  getDetailsFromVin,
};

export default CatalyticConverterService;
