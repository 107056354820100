import api from "../utils/axios-client";

/**
 * Gets all manifests
 */
const getAll = async (statusId = null) =>
  api.get("/v1/manifests", {
    params: {
      statusId: statusId,
    },
  });

const get = async (id) => api.get(`/v1/manifests/${id}`);

const createCatEntry = async (manifestEntryId, frontImage, backImage, codeImage, stockTagImage, catalyticConverterId, completedCallback) => {
  const formData = new FormData();
  formData.append("frontImage", frontImage);
  formData.append("backImage", backImage);
  formData.append("codeImage", codeImage);
  formData.append("stockTagImage", stockTagImage);
  if (catalyticConverterId) formData.append("converterConnectionsConverterId", catalyticConverterId);
  // if (catalyticConverterId) formData.append("catalyticConverterId", catalyticConverterId);

  return api.post(`v1/manifests/entries/${manifestEntryId}/catalytic-converter`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      // Include other headers if necessary
    },
    onUploadProgress: function (progressEvent) {
      var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      completedCallback(percentCompleted);
    },
  });
};

const ManifestService = {
  getAll,
  get,
  createCatEntry,
};

export default ManifestService;
