import React from "react";
import { Dialog, DialogContent, IconButton, Typography } from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import PropTypes from "prop-types";
import background from "../../assets/images/manifest-bg.png";

const StepDialog = ({ maxWidth = "sm", open, onClose, header, children }) => {
  return (
    <Dialog maxWidth={maxWidth} open={open} onClose={onClose}>
      <DialogContent
        sx={{
          background: `linear-gradient(0deg, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)), url(${background})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <IconButton size="large" sx={{ position: "absolute", top: 8, right: 8 }} onClick={onClose}>
          <CloseOutlined />
        </IconButton>
        <Typography variant="h4" sx={{ mt: 2 }}>
          {header}
        </Typography>
        {children}
      </DialogContent>
    </Dialog>
  );
};

StepDialog.propTypes = {
  maxWidth: PropTypes.string,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  header: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
};

export default StepDialog;
