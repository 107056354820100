import Button from "@mui/material/Button";
import { Box, IconButton, TextField, Typography, useTheme } from "@mui/material";
import { ArrowForward, CameraEnhance, Cancel, CheckCircle, QuestionMark } from "@mui/icons-material";
import { useState } from "react";
import StepDialog from "../dialogs/StepDialog";
import primaryCode1 from "../../assets/images/steps/primary-code-1.png";
import primaryCode2 from "../../assets/images/steps/primary-code-2.png";
import primaryCode3 from "../../assets/images/steps/primary-code-3.png";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { setCatEntry, setPrimaryCodeCatalyticConverter } from "../../redux/reducers/manifestSlice";
import placeholderImage from "../../assets/images/image-placeholder.png";
import { ImageUploadButton } from "../buttons/ImageUploadButton";
import { useQuery } from "react-query";
import { QUERIES } from "../../utils/constants.js";
import CatalyticConverterService from "../../services/CatalyticConverterService.js";
import { Loading } from "../Loading";

const PHOTO_STEPS = {
  CODE_ENTER: 0,
  CODE_PROMPT: 1,
  CODE_PREVIEW: 2,
};

export const PrimaryCodeStep = ({ handleNext }) => {
  const theme = useTheme();

  const [step, setStep] = useState(PHOTO_STEPS.CODE_ENTER);
  const [codeImageUrl, setCodeImageUrl] = useState();
  const [codeImageFile, setCodeImageFile] = useState();

  const primaryCodeCatalyticConverter = useSelector((state) => state.manifest.primaryCodeCatalyticConverter);
  const catEntry = useSelector((state) => state.manifest.catEntry);
  const dispatch = useDispatch();

  const [isDialogOpen, setDialogOpen] = useState(false);

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleNextStep = () => {
    if (step === PHOTO_STEPS.CODE_PREVIEW) {
      var cat = { ...catEntry, codeImage: codeImageFile };

      dispatch(setCatEntry(cat));
      handleNext(cat);
    } else {
      setStep(step + 1);
    }
  };

  const handlePreviousStep = () => {
    setStep(step - 1);
  };

  const { data: converters, isLoading: isConvertersLoading } = useQuery([QUERIES.ConverterConnectionsConverters], CatalyticConverterService.getAutoCompleteConverters);

  var cats =
    converters?.data?.result?.length > 0 &&
    converters?.data?.result?.map((cat) => {
      return {
        label: cat.code,
        value: cat.id,
      };
    });

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    stringify: (option) => option.label,
    limit: 10,
  });

  var headerText = "Enter the primary code located on the converter.";
  if (step === PHOTO_STEPS.CODE_PROMPT) headerText = "Take a photo of the primary code.";
  else if (step === PHOTO_STEPS.CODE_PREVIEW) headerText = "Can you see the primary code?";

  var image = placeholderImage;

  if (step === PHOTO_STEPS.CODE_PREVIEW) image = codeImageUrl;

  var imageBox = <Box component="img" src={image} sx={{ mt: 3, maxHeight: 300 }} />;

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      {isConvertersLoading && <Loading />}
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Typography variant="h5">{headerText}</Typography>
        <IconButton sx={{ backgroundColor: theme.palette.primary.main, color: theme.palette.primary.contrastText }} onClick={handleDialogOpen}>
          <QuestionMark />
        </IconButton>
      </Box>
      {step === PHOTO_STEPS.CODE_ENTER && (
        <>
          <Autocomplete
            disabled={isConvertersLoading}
            loading={isConvertersLoading}
            loadingText="Loading..."
            filterOptions={filterOptions}
            options={cats ?? []}
            autoHighlight
            getOptionLabel={(option) => option.label}
            renderOption={(props, option) => (
              <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                {option.label}
              </Box>
            )}
            renderInput={(params) => <TextField {...params} label="Primary Code" variant="filled" />}
            onChange={(event, newValue) => {
              var cat = converters?.data?.result?.find((cat) => cat?.id === newValue?.value);
              dispatch(setPrimaryCodeCatalyticConverter(cat));
            }}
          />
          <Box sx={{ mt: 4, display: "flex", justifyContent: "space-between" }}>
            <Button
              size="large"
              startIcon={<Cancel />}
              variant="outlined"
              onClick={() => {
                dispatch(setPrimaryCodeCatalyticConverter(null));
                handleNextStep();
              }}>
              NO NUMBER
            </Button>
            <Button size="large" startIcon={<ArrowForward />} variant="contained" disabled={!primaryCodeCatalyticConverter} onClick={handleNextStep}>
              NEXT
            </Button>
          </Box>
        </>
      )}
      {(step === PHOTO_STEPS.CODE_PROMPT || step === PHOTO_STEPS.CODE_PREVIEW) && (
        <>
          <Box sx={{ margin: "0 auto" }}>{imageBox}</Box>
          <Box sx={{ mt: 4, display: "flex", justifyContent: "space-between" }}>
            {step === PHOTO_STEPS.CODE_PROMPT ? (
              <ImageUploadButton
                buttonText="TAKE PHOTO"
                size="large"
                color="primary"
                variant="contained"
                handleFileChange={(file) => {
                  setCodeImageUrl(URL.createObjectURL(file));
                  setCodeImageFile(file);

                  handleNextStep();
                }}
              />
            ) : (
              <Button
                size="large"
                color="primary"
                variant="outlined"
                onClick={() => {
                  setCodeImageUrl(null);
                  handlePreviousStep();
                }}
                startIcon={<CameraEnhance />}>
                RETAKE
              </Button>
            )}
            {step === PHOTO_STEPS.CODE_PREVIEW && (
              <Button variant="contained" size="large" startIcon={<CheckCircle />} onClick={() => handleNextStep()}>
                YES
              </Button>
            )}
          </Box>
        </>
      )}
      <StepDialog maxWidth="sm" open={isDialogOpen} onClose={handleDialogClose} header="Primary Code">
        <Typography variant="h6">
          The primary code (primary number, primary CAN number) is normally found on the body of the converter, but sometimes can be found in other locations depending what type it is.
          <br />
          <br />
          If you’ve cleaned your converter and still can’t find a primary code, tap “No Number” button and follow the directions on the screen to move on to the next converter.
        </Typography>
        <Box sx={{ mt: 4, px: 8 }}>
          <Box component="img" src={primaryCode1} sx={{ width: "100%" }} />
        </Box>
        <Box sx={{ mt: 2, px: 8 }}>
          <Box component="img" src={primaryCode2} sx={{ width: "100%" }} />
        </Box>
        <Box sx={{ mt: 2, px: 8 }}>
          <Box component="img" src={primaryCode3} sx={{ width: "100%" }} />
        </Box>
      </StepDialog>
    </Box>
  );
};
