import Button from "@mui/material/Button";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { CameraEnhance, CheckCircle, QuestionMark } from "@mui/icons-material";
import placeholderImage from "../../assets/images/image-placeholder.png";
import stockTag from "../../assets/images/steps/stock-tag.png";
import { useState } from "react";
import { ImageUploadButton } from "../buttons/ImageUploadButton";
import StepDialog from "../dialogs/StepDialog";
import { useDispatch, useSelector } from "react-redux";
import { setCatEntry } from "../../redux/reducers/manifestSlice";

const PHOTO_STEPS = {
  STOCK_TAG_PROMPT: 0,
  STOCK_TAG_REVIEW: 1,
};

export const StockTagPhotoStep = ({ handleNext }) => {
  const theme = useTheme();

  const [photoStepData, setPhotoStepData] = useState({
    stockTag: null,
    step: PHOTO_STEPS.STOCK_TAG_PROMPT,
  });

  const [isDialogOpen, setDialogOpen] = useState(false);

  const catEntry = useSelector((state) => state.manifest.catEntry);
  const dispatch = useDispatch();

  const [stockTagImageUrl, setStockTagImageUrl] = useState();
  const [stockTagImageFile, setStockTagImageFile] = useState();

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleNextStep = () => {
    if (photoStepData.step === PHOTO_STEPS.STOCK_TAG_REVIEW) {
      dispatch(setCatEntry({ ...catEntry, stockTagImage: stockTagImageFile }));
      handleNext();
    } else {
      setPhotoStepData({ ...photoStepData, step: photoStepData.step + 1 });
    }
  };

  const handlePreviousStep = () => {
    setPhotoStepData({ ...photoStepData, step: photoStepData.step - 1 });
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Typography variant="h5">{photoStepData.step === PHOTO_STEPS.STOCK_TAG_REVIEW ? "Can you clearly see the numbers on the Stock Number Tag?" : "Take a picture of the Stock Number Tag."}</Typography>
        <IconButton sx={{ backgroundColor: theme.palette.primary.main, color: theme.palette.primary.contrastText }} onClick={handleDialogOpen}>
          <QuestionMark />
        </IconButton>
      </Box>
      <Box sx={{ margin: "0 auto" }}>
        <Box component="img" src={photoStepData.step === PHOTO_STEPS.STOCK_TAG_REVIEW ? stockTagImageUrl : placeholderImage} sx={{ mt: 3, maxHeight: 300 }} />
      </Box>
      {photoStepData.step === PHOTO_STEPS.STOCK_TAG_PROMPT ? (
        <Box sx={{ mt: 4, display: "flex", justifyContent: "flex-end" }}>
          <ImageUploadButton
            buttonText="TAKE PHOTO"
            size="large"
            color="primary"
            variant="contained"
            handleFileChange={(file) => {
              setStockTagImageUrl(URL.createObjectURL(file));
              setStockTagImageFile(file);
              handleNextStep();
            }}
          />
        </Box>
      ) : (
        <Box sx={{ mt: 4, display: "flex", justifyContent: "space-between" }}>
          <Button
            size="large"
            color="primary"
            variant="outlined"
            onClick={() => {
              setStockTagImageUrl(null);
              handlePreviousStep();
            }}
            startIcon={<CameraEnhance />}>
            RETAKE
          </Button>
          <Button variant="contained" size="large" startIcon={<CheckCircle />} onClick={() => handleNextStep()}>
            YES
          </Button>
        </Box>
      )}

      <StepDialog maxWidth="md" open={isDialogOpen} onClose={handleDialogClose} header="Stock Number Tag">
        <Typography variant="h6">Here's an example of a Stock Number Tag. The tag you have may look different, but should have similar information on it.</Typography>
        <Box sx={{ mt: 4, px: 8 }}>
          <Box component="img" src={stockTag} sx={{ width: "100%" }} />
        </Box>
      </StepDialog>
    </Box>
  );
};
