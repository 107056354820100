import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./styles/theme";
import App from "./App";

import { CssBaseline } from "@mui/material";
import Hotjar from "@hotjar/browser";
import { QueryClient, QueryClientProvider } from "react-query";
import { AuthProvider } from "./contexts/AuthContext";
import { store } from "./redux/store";
import { Provider } from "react-redux";

const siteId = 3712786;
const hotjarVersion = 6;

Hotjar.init(siteId, hotjarVersion);

const container = document.getElementById("root");
const root = ReactDOM.createRoot(container);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  },
});

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <Router>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <AuthProvider>
              <App />
            </AuthProvider>
          </ThemeProvider>
        </Router>
      </QueryClientProvider>
    </Provider>
  </React.StrictMode>,
);
