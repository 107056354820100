import Button from "@mui/material/Button";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { ArrowBack, ArrowForward, CameraEnhance, CheckCircle, QuestionMark } from "@mui/icons-material";
import placeholderImage from "../../assets/images/image-placeholder.png";
import codeNo from "../../assets/images/steps/code-no.png";
import codeYes from "../../assets/images/steps/code-yes.png";
import frontYes from "../../assets/images/steps/front-yes.png";
import frontNo from "../../assets/images/steps/front-no.png";
import photostation from "../../assets/images/steps/photo-station.png";
import { useState } from "react";
import { ImageUploadButton } from "../buttons/ImageUploadButton";
import StepDialog from "../dialogs/StepDialog";
import { useDispatch, useSelector } from "react-redux";
import { setCatEntry } from "../../redux/reducers/manifestSlice";

const PHOTO_STEPS = {
  FRONT_PROMPT: 0,
  FRONT_PREVIEW: 1,
  BACK_PROMPT: 2,
  BACK_PREVIEW: 3,
};

export const TakePhotosStep = ({ handleNext }) => {
  const theme = useTheme();

  const [photoStepData, setPhotoStepData] = useState({
    front: null,
    back: null,
    step: PHOTO_STEPS.FRONT_PROMPT,
  });
  const [isDialogOpen, setDialogOpen] = useState(false);

  const catEntry = useSelector((state) => state.manifest.catEntry);
  const dispatch = useDispatch();

  const [frontImageUrl, setFrontImageUrl] = useState();
  const [frontImageFile, setFrontImageFile] = useState();

  const [backImageUrl, setBackImageUrl] = useState();
  const [backImageFile, setBackImageFile] = useState();

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleNextStep = () => {
    if (photoStepData.step === PHOTO_STEPS.BACK_PREVIEW) {
      dispatch(setCatEntry({ ...catEntry, frontImage: frontImageFile, backImage: backImageFile }));
      handleNext();
    } else {
      setPhotoStepData({ ...photoStepData, step: photoStepData.step + 1 });
    }
  };

  const handlePreviousStep = () => {
    setPhotoStepData({ ...photoStepData, step: photoStepData.step - 1 });
  };

  var promptPhotoStep = photoStepData.step === PHOTO_STEPS.BACK_PROMPT || photoStepData.step === PHOTO_STEPS.FRONT_PROMPT;

  var headerText = "Take a picture of the front of the converter at the photo station.";
  if (photoStepData.step === PHOTO_STEPS.FRONT_PREVIEW) {
    headerText = "Is the front of the converter visible in the photo?";
  } else if (photoStepData.step === PHOTO_STEPS.BACK_PROMPT) {
    headerText = "Take a picture of the back of the converter at the photo station.";
  } else if (photoStepData.step === PHOTO_STEPS.BACK_PREVIEW) {
    headerText = "Is the back of the converter visible in the photo?";
  }

  var image = placeholderImage;

  if (photoStepData.step === PHOTO_STEPS.FRONT_PREVIEW) image = frontImageUrl;
  else if (photoStepData.step === PHOTO_STEPS.BACK_PREVIEW) image = backImageUrl;

  var imageBox = <Box component="img" src={image} sx={{ mt: 3, maxHeight: 300 }} />;

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Typography variant="h5">{headerText}</Typography>
        <IconButton sx={{ backgroundColor: theme.palette.primary.main, color: theme.palette.primary.contrastText }} onClick={handleDialogOpen}>
          <QuestionMark />
        </IconButton>
      </Box>
      <Box sx={{ margin: "0 auto" }}>{imageBox}</Box>
      <Box sx={{ mt: 4, display: "flex", justifyContent: "space-between" }}>
        {promptPhotoStep ? (
          <ImageUploadButton
            buttonText="TAKE PHOTO"
            size="large"
            color="primary"
            variant="contained"
            handleFileChange={(file) => {
              if (photoStepData.step === PHOTO_STEPS.FRONT_PROMPT) {
                setFrontImageUrl(URL.createObjectURL(file));
                setFrontImageFile(file);
              } else if (photoStepData.step === PHOTO_STEPS.BACK_PROMPT) {
                setBackImageUrl(URL.createObjectURL(file));
                setBackImageFile(file);
              }
              handleNextStep();
            }}
          />
        ) : (
          <Button
            size="large"
            color="primary"
            variant="outlined"
            onClick={() => {
              if (photoStepData.step === PHOTO_STEPS.FRONT_PREVIEW) setFrontImageUrl(null);
              else if (photoStepData.step === PHOTO_STEPS.BACK_PREVIEW) setBackImageUrl(null);
              handlePreviousStep();
            }}
            startIcon={<CameraEnhance />}
          >
            RETAKE
          </Button>
        )}
        {promptPhotoStep ? (
          <Button
            size="large"
            startIcon={photoStepData.step === PHOTO_STEPS.BACK_PROMPT && <ArrowBack />}
            onClick={() => {
              if (photoStepData.step === PHOTO_STEPS.BACK_PROMPT) handlePreviousStep();
            }}
            endIcon={photoStepData.step === PHOTO_STEPS.FRONT_PROMPT && <ArrowForward />}
          >
            {photoStepData.step === PHOTO_STEPS.FRONT_PROMPT ? "1" : "2"}/2
          </Button>
        ) : (
          <Button variant="contained" size="large" startIcon={<CheckCircle />} onClick={() => handleNextStep()}>
            YES
          </Button>
        )}
      </Box>
      <StepDialog maxWidth="sm" open={isDialogOpen} onClose={handleDialogClose} header="Photo Station">
        <Typography variant="h6">The photo station is an important part of our process. Using the guides provided, you’ll be asked to take multiple photos throughout this process.</Typography>
        <Box sx={{ mt: 4, px: 8 }}>
          <Box component="img" src={photostation} sx={{ width: "100%" }} />
        </Box>
        <Typography variant="h6">
          We rely on a series of three (sometimes more!) photos to make sure everything is in our system correctly. Because of the multiple photos required, you’ll be asked to set them up in different positions for the photos. The positions and
          photos normally asked for are as follows:
        </Typography>
        <Typography variant="h4" mt={2}>
          Front
        </Typography>
        <Typography variant="h6">Always make sure you can clearly see the measurements on yardstick when taking pictures. You should be photographing only at the photo station, using the provided background.</Typography>
        <Box sx={{ mt: 4, px: 8 }}>
          <Box component="img" src={frontYes} sx={{ width: "100%" }} />
        </Box>
        <Box sx={{ mt: 2, px: 8 }}>
          <Box component="img" src={frontNo} sx={{ width: "100%" }} />
        </Box>
        <Typography variant="h4" mt={2}>
          Back
        </Typography>
        <Typography variant="h6">You’ll take a photo of the back of the converter, pretty much the same way you did for the front! Just flip it over, line it up, and you’re good!</Typography>
        <Box sx={{ mt: 4, px: 8 }}>
          <Box component="img" src={frontYes} sx={{ width: "100%" }} />
        </Box>
        <Box sx={{ mt: 2, px: 8 }}>
          <Box component="img" src={frontNo} sx={{ width: "100%" }} />
        </Box>
        <Typography variant="h4" mt={2}>
          Primary Code
        </Typography>
        <Typography variant="h6">
          It’s important that you can clearly see the Primary code, even if that means you have to clean the converter again. When taking a photo of the primary code, make sure to zoom in so the code is easy to read.
        </Typography>
        <Box sx={{ mt: 4, px: 8 }}>
          <Box component="img" src={codeYes} sx={{ width: "100%" }} />
        </Box>
        <Box sx={{ mt: 2, px: 8 }}>
          <Box component="img" src={codeNo} sx={{ width: "100%" }} />
        </Box>
      </StepDialog>
    </Box>
  );
};
