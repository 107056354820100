import { Box, Paper, Typography, useTheme } from "@mui/material";
import { Check } from "@mui/icons-material";

export const CatLabStep = ({ label, stepNumber, active, completed, handleNumberClick }) => {
  const theme = useTheme();

  const disabledBg = "rgba(25, 65, 133, 0.1)";
  const disabledLabelBg = "rgba(0, 0, 0, 0.12)";
  const disabledLabelColor = "#FFFFFF";

  const bgColor = active ? theme.palette.primary.main : disabledBg;
  const textColor = active ? theme.palette.primary.contrastText : theme.palette.secondary.contrastText;

  const numberBgColor = active ? theme.palette.primary.contrastText : disabledLabelBg;
  const numberTextColor = active ? theme.palette.primary.main : disabledLabelColor;

  const disabledBorderStyle = {
    background: "rgba(25, 65, 133, 0.1)",
    borderWidth: "2px 2px 2px 0px",
    borderStyle: "solid",
    borderColor: "rgba(51, 77, 114, 0.24)",
  };

  var width = active ? "95%" : "30%";

  var mainPaperStyle = {
    width: width,
    marginTop: 2,
    backgroundColor: bgColor,
    color: textColor,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    p: 1,
    borderTopRightRadius: 80,
    borderBottomRightRadius: 80,
    transition: "width 0.5s ease-in-out",
  };

  if (!active) {
    mainPaperStyle = { ...mainPaperStyle, ...disabledBorderStyle };
  }

  const labelStyle = {
    display: "flex",
    alignItems: "center",
    transition: "opacity 0.3s ease-in-out 0.3s", // Adding transition property for text
    opacity: active ? 1 : 0, // Adjust opacity based on active state
    whiteSpace: "nowrap", // Prevent text from wrapping
    overflow: "hidden", // Hide any overflowing text
    textOverflow: "ellipsis", // Show ellipsis for truncated text
  };

  return (
    <Paper elevation={9} sx={mainPaperStyle} onClick={handleNumberClick}>
      <Box sx={labelStyle}>
        <Typography variant="h5">{active ? label : ""}</Typography>
      </Box>

      <Box sx={{ borderRadius: "50%", marginRight: 1, height: 56, width: 56, backgroundColor: numberBgColor, color: numberTextColor, display: "flex", alignItems: "center", justifyContent: "center" }}>
        {!completed ? <Typography variant="h5">{stepNumber}</Typography> : <Check color="success" fontSize="large" />}
      </Box>
    </Paper>
  );
};
