import Button from "@mui/material/Button";
import { Box, Chip, Divider, Grid, Typography } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import { CatLabStep } from "../ui-components/CatLabStep";
import { StockNumberStep } from "../ui-components/steps/StockNumberStep";
import { CompletedStep } from "../ui-components/steps/CompletedStep";
import { TakePhotosStep } from "../ui-components/steps/TakePhotosStep";
import { PrimaryCodeStep } from "../ui-components/steps/PrimaryCodeStep";
import { StockTagPhotoStep } from "../ui-components/steps/StockTagPhotoStep";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { QUERIES } from "../utils/constants";
import ManifestService from "../services/ManifestService";
import { useDispatch, useSelector } from "react-redux";
import { setActiveStep, setCatEntry, setManifest, setManifestEntry, setPrimaryCodeCatalyticConverter } from "../redux/reducers/manifestSlice";
import Moment from "react-moment";
import { Loading } from "../ui-components/Loading";
import { useState } from "react";
import "react-medium-image-zoom/dist/styles.css";
import { LoadingAbsolute } from "../ui-components/LoadingAbsolute";

export const Manifest = () => {
  const { id } = useParams();

  const { isLoading: manifestLoading } = useQuery(
    [QUERIES.Manifests, id],
    async () => {
      const data = await ManifestService.get(id);
      dispatch(setManifest(data?.data?.result));
    },
    {
      enabled: !!id,
    },
  );

  const createCatEntryMutation = useMutation({
    mutationFn: (catEntry) => {
      return ManifestService.createCatEntry(manifestEntry?.id, catEntry?.frontImage, catEntry?.backImage, catEntry?.codeImage, catEntry?.stockTagImage, primaryCodeCatalyticConverter?.id ?? null, setCompleted);
    },
    onSuccess: () => {
      queryClient.invalidateQueries([QUERIES.CatalyticConverters, manifestEntry?.vin]);
    },
  });

  const [completed, setCompleted] = useState(0);

  const queryClient = useQueryClient();

  const activeStep = useSelector((state) => state.manifest.activeStep);
  const manifest = useSelector((state) => state.manifest.manifest);
  const manifestEntry = useSelector((state) => state.manifest.manifestEntry);
  const primaryCodeCatalyticConverter = useSelector((state) => state.manifest.primaryCodeCatalyticConverter);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const handleNext = () => {
    activeStep < 5 && dispatch(setActiveStep(activeStep + 1));
  };

  const setStep = (step) => {
    dispatch(setActiveStep(step));
  };

  const handleComplete = async (catEntry) => {
    createCatEntryMutation.mutateAsync(catEntry);

    setStep(5);
    dispatch(setCatEntry(null));
    dispatch(setManifestEntry(null));
    dispatch(setPrimaryCodeCatalyticConverter(null));
  };

  return (
    <Grid container>
      {manifestLoading && <Loading />}
      {createCatEntryMutation.isLoading && <LoadingAbsolute percentage={completed} />}
      <Grid item sm={3} sx={{ backgroundColor: "#EFF2F9", borderRight: 1, height: "calc(100VH - 64px)", borderRightColor: "rgba(0, 0, 0, 0.12)" }}>
        <Button size="large" onClick={() => navigate("/")} startIcon={<ArrowBack />}>
          BACK
        </Button>
        <Typography px={2} mt={1} variant="body1">
          Manifest created{" "}
          <Moment format="LL" utc local>
            {manifest?.created}
          </Moment>{" "}
          by {manifest?.user?.name}
        </Typography>
        <CatLabStep label="Stock Number" stepNumber={1} active={activeStep === 1} completed={activeStep > 1} handleNumberClick={() => activeStep > 1 && setStep(1)} />
        <CatLabStep label="Stock# Tag" stepNumber={2} active={activeStep === 2} completed={activeStep > 2} handleNumberClick={() => activeStep > 2 && setStep(2)} />
        <CatLabStep label="Take Photos" stepNumber={3} active={activeStep === 3} completed={activeStep > 3} handleNumberClick={() => activeStep > 3 && setStep(3)} />
        <CatLabStep label="Primary Code" stepNumber={4} active={activeStep === 4} completed={activeStep > 4} handleNumberClick={() => activeStep > 4 && setStep(4)} />
        <CatLabStep label="Completed!" stepNumber={5} active={activeStep === 5} completed={activeStep > 5} handleNumberClick={() => activeStep > 5 && setStep(5)} />
      </Grid>
      <Grid item sm={9}>
        <Box sx={{ px: 5 }}>
          <Box sx={{ marginTop: 5, display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h3">{manifest?.name}</Typography>
            <Box sx={{ display: "flex", gap: 1 }}>
              <Chip color="info" label="In Progress" />
              {manifestEntry?.stockNumber && <Chip label={manifestEntry?.stockNumber} />}
            </Box>
          </Box>
          <Box sx={{ my: 2, display: "flex", justifyContent: "flex-end" }}>
            <Box style={{ display: "flex", flexDirection: "row" }}>
              <Typography sx={{ fontSize: 16, fontWeight: 600, lineHeight: "18px", letterSpacing: "0.16px" }}>YMM:</Typography>
              {manifestEntry?.vehicleId ? (
                <Typography sx={{ fontSize: 16, lineHeight: "18px", letterSpacing: "0.15px", mr: 3 }}>{`${manifestEntry?.vehicle?.year} ${manifestEntry?.vehicle?.make} ${manifestEntry?.vehicle?.model}`}</Typography>
              ) : (
                <Typography sx={{ fontSize: 16, lineHeight: "18px", letterSpacing: "0.15px", mr: 3 }}>N/A</Typography>
              )}
              <Typography sx={{ fontSize: 16, fontWeight: 600, lineHeight: "18px", letterSpacing: "0.16px" }}>VIN:</Typography>
              <Typography sx={{ fontSize: 16, lineHeight: "18px", letterSpacing: "0.15px" }}>{manifestEntry?.vin ?? "N/A"}</Typography>
            </Box>
          </Box>
          <Divider />
        </Box>
        <Box sx={{ mt: 4, px: 10 }}>
          {manifest && (
            <>
              {activeStep === 1 && <StockNumberStep handleNext={handleNext} handleNoNumber={() => setStep(5)} />}
              {activeStep === 2 && <StockTagPhotoStep handleNext={handleNext} />}
              {activeStep === 3 && <TakePhotosStep handleNext={handleNext} />}
              {activeStep === 4 && <PrimaryCodeStep handleNext={handleComplete} />}
              {activeStep === 5 && <CompletedStep handleNext={() => setStep(1)} />}
            </>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};
