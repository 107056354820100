import Button from "@mui/material/Button";
import { Box, IconButton, TextField, Typography, useTheme } from "@mui/material";
import { ArrowForward, Cancel, QuestionMark } from "@mui/icons-material";
import { useState } from "react";
import stockNumber from "../../assets/images/steps/stock-number.png";
import StepDialog from "../dialogs/StepDialog";
import { useDispatch, useSelector } from "react-redux";
import Autocomplete from "@mui/material/Autocomplete";
import { setManifestEntry } from "../../redux/reducers/manifestSlice";

export const StockNumberStep = ({ handleNoNumber, handleNext }) => {
  const theme = useTheme();

  const [isDialogOpen, setDialogOpen] = useState(false);

  const entries = useSelector((state) => state.manifest.manifest?.manifestEntries);
  const manifestEntry = useSelector((state) => state.manifest.manifestEntry);

  const dispatch = useDispatch();

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const options = entries.map((entry) => entry.stockNumber).filter((value, index, self) => self.indexOf(value) === index);

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Typography variant="h5">Enter the stock number located on the ticket or converter.</Typography>
        <IconButton sx={{ backgroundColor: theme.palette.primary.main, color: theme.palette.primary.contrastText }} onClick={handleDialogOpen}>
          <QuestionMark />
        </IconButton>
      </Box>
      <Autocomplete
        openOnFocus
        autoFocus
        autoHighlight
        onChange={(event, newValue) => {
          var me = entries.find((entry) => entry.stockNumber === newValue);
          dispatch(setManifestEntry(me));
        }}
        defaultValue={manifestEntry?.stockNumber}
        sx={{
          mt: 2,
          "& .MuiAutocomplete-input, & .MuiInputLabel-root": {
            fontSize: 32,
            lineHeight: "24px",
          },
        }}
        options={options ?? []}
        getOptionLabel={(option) => option}
        renderOption={(props, option) => (
          <Typography
            {...props}
            variant="input-text"
            style={{
              paddingTop: 32,
              paddingBottom: 32,
              fontSize: "32px",
              lineHeight: "24px",
            }}>
            {option}
          </Typography>
        )}
        renderInput={(params) => <TextField {...params} autoFocus hiddenLabel variant="filled" name="stockNumber" />}
      />
      <Box sx={{ mt: 4, display: "flex", justifyContent: "space-between" }}>
        <Button size="large" startIcon={<Cancel />} variant="outlined" onClick={handleNoNumber}>
          NO NUMBER
        </Button>
        <Button size="large" startIcon={<ArrowForward />} variant="contained" disabled={!manifestEntry?.id} onClick={handleNext}>
          NEXT
        </Button>
      </Box>
      <StepDialog maxWidth="sm" open={isDialogOpen} onClose={handleDialogClose} header="Stock Number">
        <Typography variant="h6">
          The stock number can be found here on the ticket attached to the converter.
          <br />
          <br />
          If you can’t find a ticket, the stock number might be written on the converter. If you can’t locate the ticket, and there isn’t a number written on the converter, tap the “No Number” button and follow the directions on the screen to move on to the next converter.
        </Typography>
        <Box sx={{ mt: 4, px: 8 }}>
          <Box component="img" src={stockNumber} sx={{ width: "100%" }} />
        </Box>
      </StepDialog>
    </Box>
  );
};
