import Button from "@mui/material/Button";
import { Box, Card, CardContent, CardHeader, Container, Grid, useTheme, TextField } from "@mui/material";
import logoFile from "../assets/images/the-catlab.png";
import bgImage from "../assets/images/login-bg.png";
import { auth } from "../utils/firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useFormik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";

export const Login = () => {
  const navigate = useNavigate();
  const theme = useTheme();

  const validationSchema = yup.object({
    email: yup.string().email("Enter a valid email").required("Email is required"),
    password: yup.string().required("Password is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        await signInWithEmailAndPassword(auth, values.email, values.password);

        navigate("/");
      } catch (error) {
        formik.setErrors({ email: "Invalid email or password", password: "Invalid email or password" });
      }
    },
  });

  return (
    <Container maxWidth={false} disableGutters>
      <Grid container>
        <Grid item xs={12} sm={6} sx={{ display: { xs: "none", sm: "flex" }, backgroundColor: theme.palette.primary.main, height: "100VH", justifyContent: "center", alignItems: "center" }}>
          <Box component="img" src={logoFile} alt="the-catlab" sx={{ maxWidth: "80%" }} />
        </Grid>
        <Grid item xs={12} sm={6} sx={{ backgroundImage: `url(${bgImage})`, height: "100VH", backgroundSize: "100% 100%", backgroundRepeat: "no-repeat", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Grid container justifyContent="center" alignItems="center" spacing={1}>
            <Card>
              <CardHeader title="Sign In" subheader="Enter your Control Center credentials below to sign in" />
              <CardContent>
                <form onSubmit={formik.handleSubmit}>
                  <TextField fullWidth id="email" name="email" label="Email Address" value={formik.values.email} onChange={formik.handleChange} error={formik.touched.email && Boolean(formik.errors.email)} helperText={formik.touched.email && formik.errors.email} margin="normal" />
                  <TextField
                    fullWidth
                    id="password"
                    name="password"
                    label="Password"
                    type="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    error={formik.touched.password && Boolean(formik.errors.password)}
                    helperText={formik.touched.password && formik.errors.password}
                    margin="normal"
                  />
                  <Button sx={{ mt: 3 }} size="large" color="primary" variant="contained" fullWidth type="submit" disabled={formik.isSubmitting}>
                    SIGN IN
                  </Button>
                </form>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};
