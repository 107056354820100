export const QUERIES = {
  Manifests: "manifests",
  Users: "users",
  ConverterConnectionsConverters: "converterConnectionsConverters",
  CatalyticConverters: "catalyticConverters",
};

export const MANIFEST_STATUS = {
  DRAFT: 1,
  IN_PROGRESS: 2,
  IN_REVIEW: 3,
  COMPLETED: 4,
};

export const MANIFEST_STATUS_LABELS = {
  YEAR_MAKE_MODEL_ENGINE_CC: "year-make-model-cc",
  YEAR_MAKE_MODEL_ENGINE_L: "year-make-model-liters",
  YEAR_MAKE_ENGINE_CC: "year-make-cc",
  YEAR_MAKE_ENGINE_L: "year-make-liters",
  MAKE_ENGINE_CC: "make-cc",
  MAKE_ENGINE_L: "make-liters",
  MAKE: "make",
};

export const CONFIG_KEYS = {
  FIREBASE_API_KEY: process.env.REACT_APP_FIREBASE_API_KEY,
  API_URL: process.env.REACT_APP_API_URL,
};

export const IMAGES = {
  LOGO: require("../assets/images/the-catlab.png"),
};
