import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { Box, Typography } from "@mui/material";

export const LoadingAbsolute = ({ percentage = null }) => {
  return (
    <Box
      sx={{
        position: "absolute",
        color: "#fff",
        borderRadius: 2,
        bottom: 16,
        left: 16,
        backgroundColor: (theme) => theme.palette.primary.main,
        zIndex: (theme) => theme.zIndex.drawer + 1,
        display: "flex",
        flexDirection: "column",
        p: 2,
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        gap: 1,
        minWidth: 150,
      }}>
      {percentage && <Typography variant="h5">{percentage}%</Typography>}
      <CircularProgress variant={percentage ? "determinate" : "indeterminate"} color="inherit" value={percentage} />
    </Box>
  );
};
