import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  activeStep: 1,
  manifest: null,
  catEntry: null,
  manifestEntry: null,
  primaryCodeCatalyticConverter: null,
};

export const manifestSlice = createSlice({
  name: "manifest",
  initialState,
  reducers: {
    setActiveStep: (state, action) => {
      state.activeStep = action.payload;
    },
    setManifest: (state, action) => {
      state.manifest = action.payload;
    },
    setCatEntry: (state, action) => {
      state.catEntry = action.payload;
    },
    setManifestEntry: (state, action) => {
      state.manifestEntry = action.payload;
    },
    setPrimaryCodeCatalyticConverter: (state, action) => {
      state.primaryCodeCatalyticConverter = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setActiveStep, setCatEntry, setManifest, setManifestEntry, setPrimaryCodeCatalyticConverter, reset } = manifestSlice.actions;

export default manifestSlice.reducer;
