import Button from "@mui/material/Button";
import { Box, Typography } from "@mui/material";
import { ArrowForward } from "@mui/icons-material";
import completedFile from "../../assets/images/completed.gif";

export const CompletedStep = ({ handleNext }) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
      <Typography variant="h3">Job Completed!</Typography>
      <Box component="img" src={completedFile} sx={{ height: 300, mt: 1 }} />
      <Box sx={{ mt: 2, justifyContent: "flex-end", flexDirection: "row" }}>
        <Button size="large" startIcon={<ArrowForward />} sx={{ mt: 2 }} variant="contained" onClick={handleNext}>
          START NEXT
        </Button>
      </Box>
    </Box>
  );
};
