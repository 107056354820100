import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { Link as RouterLink } from "react-router-dom";
import { Box, FormControlLabel, IconButton, Switch } from "@mui/material";
import logoFile from "../assets/images/the-catlab.png";
import { ExitToApp } from "@mui/icons-material";
import { useQuery } from "react-query";
import { QUERIES } from "../utils/constants";
import UserService from "../services/UserService";
import { signOut } from "firebase/auth";
import { auth } from "../utils/firebase";

const NavBar = () => {
  const { data: user } = useQuery([QUERIES.Users], () => UserService.getMe());

  const handleLogout = () => {
    signOut(auth);
  };

  return (
    <AppBar position="relative">
      <Toolbar>
        <Box sx={{ flexGrow: 1, display: "flex", alignItems: "center" }}>
          <Link component={RouterLink} to="/" color="inherit" variant="h6">
            <Box component="img" src={logoFile} sx={{ height: 40, mt: 1 }} />
          </Link>

          <FormControlLabel sx={{ marginLeft: 2 }} control={<Switch color="default" />} label="Español" />
        </Box>

        <Typography variant="h6">{user?.data?.result?.email}</Typography>
        <IconButton onClick={() => handleLogout()} color="inherit">
          <ExitToApp />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;
