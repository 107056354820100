import Button from "@mui/material/Button";
import { Card, CardActions, CardContent, CardHeader, Divider, Grid, Typography, useTheme } from "@mui/material";
import bgImage from "../assets/images/manifest-bg.png";
import { ArrowForward, ExitToApp } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { MANIFEST_STATUS, QUERIES } from "../utils/constants";
import manifestService from "../services/ManifestService";
import Moment from "react-moment";
import { Loading } from "../ui-components/Loading";
import { signOut } from "firebase/auth";
import { auth } from "../utils/firebase";

export const Home = () => {
  const { data: manifests, isLoading: manifestsLoading } = useQuery([QUERIES.Manifests], () => manifestService.getAll(MANIFEST_STATUS.IN_PROGRESS));

  let navigate = useNavigate();
  const theme = useTheme();

  const handleLogout = () => {
    signOut(auth);
  };

  return (
    <>
      {manifestsLoading && <Loading />}
      <Grid container sx={{ pt: 3, backgroundImage: `url(${bgImage})`, height: "100vh", backgroundSize: "100% 100%", backgroundRepeat: "no-repeat", justifyContent: "center" }}>
        <Grid item xs={11} sm={8}>
          {manifests &&
            manifests?.data?.result?.length > 0 &&
            manifests?.data?.result?.map((manifest) => (
              <Card sx={{ mb: 3 }}>
                <CardHeader sx={{ backgroundColor: theme.palette.info.main, color: theme.palette.info.contrastText }} title="AVAILABLE MANIFEST" />
                <CardContent sx={{ p: 5 }}>
                  <Typography variant="h3" color="text.primary">
                    {manifest.name}
                  </Typography>
                  <Typography variant="h6" color="text.primary">
                    Created{" "}
                    <Moment format="LLL" utc local>
                      {manifest?.created}
                    </Moment>{" "}
                    by {manifest?.user?.name}
                  </Typography>
                  <Divider sx={{ mt: 5, mb: 0 }} />
                </CardContent>
                <CardActions sx={{ justifyContent: "space-between", p: 5, pt: 2 }}>
                  <Button size="large" startIcon={<ExitToApp />} variant="outlined" onClick={handleLogout}>
                    SIGN OUT
                  </Button>
                  <Button
                    size="large"
                    startIcon={<ArrowForward />}
                    variant="contained"
                    onClick={() => {
                      navigate(`/manifest/${manifest.id}`);
                    }}>
                    START
                  </Button>
                </CardActions>
              </Card>
            ))}
          {manifests && manifests?.data?.result?.length === 0 && (
            <Card>
              <CardHeader sx={{ backgroundColor: theme.palette.info.main, color: theme.palette.info.contrastText }} title="AVAILABLE MANIFEST" />
              <CardContent>
                <Typography variant="h4" color="text.primary">
                  There’s currently no available Manifest. Please see your supervisor.
                </Typography>
                <Divider sx={{ mt: 8, mb: 4 }} />
              </CardContent>
              <CardActions sx={{ justifyContent: "space-between", p: 2 }}>
                <Button size="large" startIcon={<ExitToApp />} variant="outlined" onClick={handleLogout}>
                  SIGN OUT
                </Button>
                <Button size="large" startIcon={<ArrowForward />} variant="contained" disabled>
                  START
                </Button>
              </CardActions>
            </Card>
          )}
        </Grid>
      </Grid>
    </>
  );
};
